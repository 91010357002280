import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Footer from "./components/Footer";
import HelmetComponent from "./components/HelmetComponent";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./components/About";
import Products from "./components/Products";
import ViewProduct from "./components/ViewProduct";
import Privacy from "./components/Privacy";
import Return from "./components/Return";
import ScrollToTop from "./hooks/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <HelmetComponent />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route
          path="/products/view-product/:productSlug"
          element={<ViewProduct />}
        />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/return-and-refund" element={<Return />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
