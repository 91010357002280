// FeaturedProducts.js
import React from "react";
import "swiper/less";
import "swiper/less/navigation";
import "swiper/less/pagination";
import "swiper/less/scrollbar";
import SellingProducts from "./SellingProducts";

const Home = () => {
  const style = {
    background: "url(/images/home-hero.png) no-repeat",
    backgroundPosition: "top",
  };
  return (
    <>
      <section className="site-banner jarallax padding-large" style={style}>
        <div className="container">
          <div className="row">
            <div className="col-md-12"></div>
          </div>
        </div>
      </section>
      {/* <FeaturedProducts /> */}
      <SellingProducts />
    </>
  );
};

export default Home;
