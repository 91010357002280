import React, { useEffect, useState } from "react";
import productsData from "../config/best-selling-products.json";
import ProductTile from "./ProductTile";

const SellingProducts = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Simulate fetching data from JSON
    setProducts(productsData);
  }, []);

  return (
    <section
      id="selling-products"
      className="product-store bg-light-grey padding-large"
    >
      <div className="container">
        <div className="section-header">
          <h2>Best selling products</h2>
        </div>
        <div className="tab-content">
          <div>
            <div className="row d-flex flex-wrap">
              {products.map((product, index) => (
                <div
                  key={index}
                  className="product-item col-lg-3 col-md-6 col-sm-6"
                >
                  <ProductTile product={product} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SellingProducts;
