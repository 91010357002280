import React from 'react'
import { Helmet } from 'react-helmet-async'

const HelmetComponent = () =>
  (<Helmet>
        <title>Yiwu Qingcheng E-Commerce</title>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="format-detection" content="telephone=no"/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>
        <link rel="stylesheet" type="text/css" href="/css/normalize.css"/>
        <link rel="stylesheet" type="text/css" href="/icomoon/icomoon.css"/>
        <link rel="stylesheet" type="text/css" media="all"
              href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"/>
        <link rel="stylesheet" type="text/css" href="/css/vendor.css"/>
        <link rel="stylesheet" type="text/css" href="/style.css"/>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true"/>
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"/>
        <script src="/js/modernizr.js"></script>
  </Helmet>)

export default HelmetComponent
