import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";

const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [expanded, setExpanded] = useState(false);

  return (
    <header id="header">
      <Navbar
        expanded={expanded}
        bg="light"
        expand="lg"
        className="border-bottom fixed-top"
      >
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img src="/images/main-logo2.png" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
            className={expanded ? "navbar-toggler-icond" : ""}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                onClick={() => setExpanded(false)}
                as={Link}
                to="/"
                className={
                  currentPath === "/"
                    ? "item-anchor active me-5"
                    : "item-anchor me-5"
                }
                data-effect="Home"
              >
                Home
              </Nav.Link>
              <Nav.Link
                onClick={() => setExpanded(false)}
                as={Link}
                to="/about"
                className={
                  currentPath === "/about"
                    ? "item-anchor active me-5"
                    : "item-anchor me-5"
                }
                data-effect="About"
              >
                About
              </Nav.Link>
              <Nav.Link
                onClick={() => setExpanded(false)}
                as={Link}
                to="/products"
                className={
                  currentPath === "/products"
                    ? "item-anchor active me-5"
                    : "item-anchor me-5"
                }
                data-effect="Shop"
              >
                Products
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
