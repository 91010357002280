import React from 'react';

const Privacy = () => {
  return (
    <div className="container mt-5">
      <h3 className="mb-4">Privacy Policy</h3>
      <p className="lead">
        Welcome to Yiwu Qingcheng E-Commerce Firm!
      </p>
      <p>
        Founded in 2019 and based in Yiwu—the bustling hub of small commodities—our company specializes in the production and sales of high-quality auto parts. We pride ourselves on our strong partnerships with numerous domestic dealers and international customers, extending our reach to the Americas, Europe, the Middle East, Africa, Southeast Asia, and beyond.
      </p>
      <p>
        At Yiwu Qingcheng, we are committed to integrity and win-win cooperation. Our goal is to deliver exceptional value to our customers while maintaining the highest standards of service. Over the years, we have earned the trust and satisfaction of many customers both at home and abroad.
      </p>
      <p>
        Thank you for choosing Yiwu Qingcheng E-Commerce Firm. We look forward to continuing to serve you with excellence.
      </p>
      <hr />
      <h4 className="mt-4">Information Collection</h4>
      <p>
        We may collect personal information that you provide directly to us, such as your name, contact details, and any other information you provide while interacting with our services.
      </p>
      <h4 className="mt-4">Use of Information</h4>
      <p>
        The information we collect is used to provide, maintain, and improve our services, to communicate with you, and to ensure the security of our operations.
      </p>
      <h4 className="mt-4">Data Security</h4>
      <p>
        We employ appropriate technical and organizational measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
      </p>
      <h4 className="mt-4">Changes to This Policy</h4>
      <p>
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
      </p>
      <h4 className="mt-4">Contact Us</h4>
      <p>
        If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@yiwu-qingcheng.com">info@yiwu-qingcheng.com</a>.
      </p>
    </div>
  );
}

export default Privacy;
