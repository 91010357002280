import React from 'react'
import { Link } from 'react-router-dom'

const MyComponent = () => {
  return (
    <>
      <footer id="footer">
        <div className="container margin-bottom-large">
          <div className="footer-menu-list">
            <div className="row d-flex flex-wrap justify-content-between">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="footer-menu">
                  <h5 className="widget-title">Yiwu Qingcheng</h5>
                  <ul className="menu-list list-unstyled">
                    <li className="menu-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/products">Products</Link>
                    </li>
                    <li>
                      <Link to="/about">About us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="footer-menu">
                  <h5 className="widget-title">Customer Service</h5>
                  <ul className="menu-list list-unstyled">
                    <li className="menu-item">
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/return-and-refund">Returns & Refunds</Link>
                    </li>
                    <li className="menu-item">
                      <p>
                        Phone: +8613868961287<br/>
                        Email: nihao520052@qq.com
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="footer-menu">
                  <h5 className="widget-title">Contact Us</h5>
                  <p>No. 10 Longgang Road,
                    Heyetang Industrial Zone
                    Yiwu, Zhejiang Province
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr/>
      </footer>

      <div id="footer-bottom">
        <div className="container">
          <div className="d-flex align-items-center flex-wrap justify-content-center">
            <div className="copyright">
              <p>2024 All rights reserved <a href="https://yiwu-qingcheng.com">Yiwu Qingcheng E-Commerce</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyComponent
