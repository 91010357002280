import React from 'react'
import { useParams } from 'react-router-dom'
import products from '../config/products.json';

const ViewProduct = () => {
  const { productSlug } = useParams();
  const product = products.find(prod => prod.slug === productSlug);
  if (!product) {
    return <div>Product not found</div>;
  }

  console.log(product);
  return (
    <>
      <section id="view-product">
        <div className="container ">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="image-holder">
                <img src={product.image} alt={product.title} className="about-image"/>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="detail">
                <div className="display-header">
                  <h3>{product.title}</h3>
                  <p>{product.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ViewProduct
