import React, { useEffect, useState } from 'react'
import ProductTile from './ProductTile'
import productsData from '../config/products.json';

const Products = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    // Simulate fetching data from JSON
    setProducts(productsData);
  }, []);

  return (
    <>
      <div className="shopify-grid padding-large">
        <div className="container">
          <div className="row">

            <section id="selling-products" className="col-md-12 product-store">
              <div className="container">
                <div className="tab-content">
                    <div className="row d-flex flex-wrap">
                      {products.map((product, index) => (
                        <div key={index} className="product-item col-lg-4 col-md-6 col-sm-6">
                          <ProductTile product={product} />
                        </div>
                      ))}
                    </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default Products
