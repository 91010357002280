import React from 'react';

const About = () => {
  return (
    <>
      <section id="about-us">
        <div className="container ">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="image-holder">
                <img src="/images/about-us.jpg" alt="single" className="about-image"/>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="detail">
                <div className="display-header">
                  <h3>About Yiwu Qingcheng</h3>
                  <p>
                    Welcome to Yiwu Qingcheng E-Commerce Firm!<br />
                    Founded in 2019 and based in Yiwu—the bustling hub of small commodities—our
                    company specializes in the production and sales of high-quality auto parts.
                    We pride ourselves on our strong partnerships with numerous domestic dealers
                    and international customers, extending our reach to the Americas, Europe,
                    the Middle East, Africa, Southeast Asia, and beyond.<br />
                    At Yiwu Qingcheng, we are committed to integrity and win-win cooperation.
                    Our goal is to deliver exceptional value to our customers while maintaining
                    the highest standards of service. Over the years, we have earned the trust
                    and satisfaction of many customers both at home and abroad.<br />
                    Thank you for choosing Yiwu Qingcheng E-Commerce Firm. We look forward to
                    continuing to serve you with excellence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="brand-collection" className="padding-medium bg-light-grey">
        <div className="container">
        </div>
      </section>
    </>
  );
};

export default About;
