import React from 'react';

const Return = () => {
  return (
    <div className="container mt-5">
      <h3 className="mb-4">Return and Refund Policy</h3>
      <p className="lead">
        At Yiwu Qingcheng E-Commerce Firm, we strive to ensure our customers are satisfied with their purchases. Below is our return and refund policy.
      </p>
      <h4 className="mt-4">Returns</h4>
      <p>
        We accept returns of products within <strong>30 days</strong> of the purchase date. To be eligible for a return, the item must be in its original condition and packaging.
      </p>
      <p>
        To initiate a return, please contact our customer service team at <a href="mailto:returns@yiwu-qingcheng.com">returns@yiwu-qingcheng.com</a> with your order number and reason for return.
      </p>

      <h4 className="mt-4">Refunds</h4>
      <p>
        Once we receive and inspect your returned item, we will notify you of the approval or rejection of your refund. If approved, your refund will be processed and a credit will automatically be applied to your original payment method within <strong>7-10 business days</strong>.
      </p>
      <p>
        Please note that shipping costs are non-refundable and you will be responsible for paying the shipping costs for returning your item.
      </p>

      <h4 className="mt-4">Exchanges</h4>
      <p>
        We do not offer exchanges. If you need a different item, please return the original item and place a new order.
      </p>

      <h4 className="mt-4">Contact Us</h4>
      <p>
        If you have any questions about our return and refund policy, please reach out to our customer service team at <a href="mailto:customer.service@yiwu-qingcheng.com">customer.service@yiwu-qingcheng.com</a>.
      </p>
    </div>
  );
}

export default Return;
