import React from 'react'
import { Link } from 'react-router-dom'

const ProductTile = ({ product }) => {
  return (
    <>
        <div className="image-holder">
          <img src={product.image} alt={product.title} className="product-image"/>
        </div>
        <div className="product-detail">
          <h3 className="product-title">
            <Link to={`/products/view-product/${product.slug}`}>{product.title}</Link>
          </h3>
        </div>
     </>
  )
}

export default ProductTile
